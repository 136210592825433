<template>
  <div class="page">
    <div
      class="back-link"
      @click="$router.push({ name: 'Accounts' })"
    >
      <b-icon-chevron-left font-scale="1.3" />
      <span>
        {{ capitalizeFirstLetter($t('words.accountsManagement')) }}
      </span>
    </div>
    <div class="top">
      <b-row>
        <b-col class="left-col">
          <span>
            <img
              src="@/assets/icons/user-group.svg"
              alt="Icon user group"
            >
            {{ groupsCount }} {{ $tc('words.userGroup', groupsCount) }}
          </span>
          <div
            class="add-btn"
            @click="$router.push({ name: 'AddGroup' })"
          >
            <b-icon-plus />
            {{ $t('groups.add') }}
          </div>
        </b-col>
        <b-col class="right-col">

        </b-col>
      </b-row>
    </div>
    <SearchablePaginatedTable
      :name="'groupsListTable'"
      :loading="loadingTable"
      :searchable="true"
      :searchPlaceholder="$t('groups.search')"
      :searchFunction="SEARCH_USERGROUPS_LIST"
      :routeTo="'GroupDetail'"
      :fields="fields"
      :rows="rows"
      :count="groupsCount"
      :defaultSorting="{
        sortedUp: false,
        sortedDown: true,
        sortedField: 'date_joined'
      }"
      :sortFunction="GET_USERGROUPS_GROUPS_LIST"
      :selectable="false"
      @sorted="setTableSorted"
      @change-page="changeTablePage"
      @delete="openDeleteModal"
    />
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';

import SearchablePaginatedTable from '@/components/SearchablePaginatedTable/Layout.vue';

export default {
  name: 'Groups',

  components: {
    SearchablePaginatedTable
  },

  data() {
    return {
      loadingTable: false,
      tableSorted: {
        direction: '',
        field: 'display_name'
      },
      groupToDelete: null
    }
  },

  computed: {
    ...mapState('table', [
      'currentTables'
    ]),
    ...mapState('usergroups', [
      'groups',
      'groupsCount',
      'isGroupsSearched'
    ]),
    fields() {
      return [
        {
          key: this.$t('groups.table.fields.group'),
          apiName: 'display_name',
          sortable: true,
          width: '40%',
          centered: false
        },
        {
          key: this.$t('groups.table.fields.creationDate'),
          apiName: 'creation_date',
          sortable: true,
          width: '',
          centered: true
        },
        {
          key: this.$t('groups.table.fields.usersCount'),
          sortable: false,
          width: '',
          centered: true
        },
        {
          key: this.$t('groups.table.fields.delete'),
          sortable: false,
          width: '',
          centered: true
        }
      ]
    },
    rows() {
      return this.groups.map(el => {
        return {
          id: el.id,
          'Groupe': el.display_name,
          'Date de création': new Date(el.creation_date).toLocaleString('fr-FR', {year: "numeric", month: "long", day: "numeric"}),
          'Nombre d\'utilisateurs': el.members_count
        }
      });
    }
  },

  created() {
    this.SET_TABLE({
      name: 'groupsListTable',
      table: {
        loading: false,
        currentPage: this.currentTables.find(t => t.name === 'groupsListTable') ?
          this.currentTables.find(t => t.name === 'groupsListTable').currentPage :
          1,
        isTableSearched: false,
        searchQuery: null
      }
    });
  },

  mounted() {
    if (this.groups.length === 0 || this.isGroupsSearched) {
      this.getData();
    }

    // Watch modal closing event
    this.$root.$on('bv::modal::hide', () => {
      this.deletingGroup = false
    });
  },

  methods: {
    ...mapMutations('table', [
      'SET_TABLE'
    ]),
    ...mapMutations('modal', [
      'OPEN_MODAL',
    ]),
    ...mapActions('usergroups', [
      'GET_USERGROUPS_GROUPS_LIST',
      'SEARCH_USERGROUPS_LIST',
      'DELETE_USERGROUP'
    ]),

    getData() {
      this.loadingTable = true;
      this.GET_USERGROUPS_GROUPS_LIST({...this.tableSorted})
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    openDeleteModal(e) {
      this.groupToDelete = e;
      this.OPEN_MODAL({
        modal: 'confirmation',
        open: true,
        title: this.$t('modals.deleteGroup.title'),
        content: this.$t('modals.deleteGroup.content', { group: this.groupToDelete[this.$t('groups.table.fields.group')] }),
        trigger: this.deleteGroup
      });
    },

    deleteGroup() {
      this.loadingTable = true;
      this.DELETE_USERGROUP({
        id: this.groupToDelete.id,
        usergroupType: 'user-group'
      })
        .then(() => {
          this.getData();
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    changeTablePage(e) {
      this.loadingTable = true;
      this.GET_USERGROUPS_GROUPS_LIST({
        ...this.tableSorted,
        page: e
      })
        .then(() => {
          this.loadingTable = false;
        })
        .catch(() => {
          this.loadingTable = false;
        });
    },

    setTableSorted(e) {
      this.tableSorted = e;
    }
  }
}
</script>
